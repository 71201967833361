<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold title">Aluno</h2>
      </b-col>

      <b-col v-show="showButtons" class="text-right">
        <b-button style="margin-left: 25px" variant="outline" @click="downloadStudentReport"
          :disabled="downloadLoading">
          {{ downloadLoading ? "Aguarde..." : "Exportar" }}
        </b-button>
        <b-button class="revert-approved-btn" v-if="periodReport && periodReport.status === 'approved'"
          @click="() => pendingPeriodReportStatus()">Reverter Liberar
        </b-button>
        <b-button style="margin-left: 25px" variant="outline" v-else @click="() => aprovePeriodReportStatus()">Liberar
        </b-button>
      </b-col>
    </b-row>

    <div>
      <b-row>
        <b-col>
          <b-row class="profile-row">
            <b-col>
              <img v-if="photo == null || ''" src="@/assets/images/default-photo.png" height="64" width="64" alt />
              <img v-else width="64" height="64" :src="`${photo}`" alt />
              <div class="content-meeting">
                <div class="student-detail">
                  <h5>{{ user && user.name }}</h5>
                  <p>
                    {{ cycle.designation }} ({{ year && year.designation }} º
                    ano no ciclo) <br /> Frequência total do ano: {{ attendances.frequency }}%
                  </p>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="content-meeting">
                <div class="column">
                  <p>
                    <b>Carga horária anual</b>
                  </p>
                  <p>{{ cycle.workload }}h</p>
                </div>
                <div class="column">
                  <p>
                    <b>Limite permitido de faltas</b>
                  </p>
                  <p>{{ cycle.absence_limit_hours }}h</p>
                </div>
                <div class="column">
                  <p>
                    <b>Total de faltas/atrasos do aluno</b>
                  </p>
                  <p>{{ attendances.time_absences ? attendances.time_absences : '0h' }}</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div class="attendance-advice">
        <p>
          Ao atingir 30% do limite permitido de faltas ({{
          cycle.absence_limit_perc_responsible
          }} horas), a Escola tem obrigação legal de comunicar o Conselho
          Tutelar
        </p>
      </div>
    </div>

    <b-row style="padding-top: 36px">
      <b-col>
        <h3>Tutoria</h3>
      </b-col>

      <b-col v-show="showButtons" class="text-right">
        <button class="notify-button" @click="() => notifyTutor()" :disabled="periodReport && periodReport.description">
          Notificar pendência
        </button>
      </b-col>
    </b-row>

    <b-row style="padding-top: 15px">
      <b-col class="col-observacoes">
        <div class="content-meeting">
          <div class="observacao-column" v-if="periodReport && periodReport.description">
            <span style="font-size: 16px; color: #666666">Observação referente ao período {{ periodReport.Period.name
              }}</span>
            <p style="font-size: 18px; color: #b23066">
              {{ periodReport.description }}
            </p>
          </div>

          <div class="observacao-column" v-else>
            <p style="font-size: 18px; color: #b23066; font-weight: bold">
              Nenhuma observação registrada.
            </p>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row style="padding-top: 36px">
      <b-col>
        <h3>Evolução</h3>
      </b-col>

      <b-col v-show="showButtons" class="text-right">
        <button class="notify-button" @click="() => notifyTeacher()">
          Notificar pendência
        </button>
      </b-col>
    </b-row>

    <b-row style="padding-top: 20px">
      <b-col md="4" lg="4" class="menu-sidebar knowledge-menu" style="background-color: #f9f9f9">
        <div class="menu">
          <span class="menu-sidebar-title"> ÁREA DE CONHECIMENTO </span>
          <ul style="padding-left: 0">
            <li class="side-item" v-for="subject in subjectList" :key="subject.id"
              :class="{ active: subject_id === subject.id }" @click="() => selectSubject(subject.id)">
              <span>
                {{ subject.designation }}
              </span>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col lg="9">
        <div v-if="!loadingChart">
          <div class="chart-container">
            <h3>Percentual de evolução do aluno</h3>
            <div class="flag-wrapper">
              <b-col class="subtitle-container">
                <span><b>Legenda:</b></span>
                <div class="flag-container">
                  <div class="subtitle">
                    <div class="flag-box">
                      <Flag />
                    </div>
                    <span> Evolução anual </span>
                  </div>

                  <div class="subtitle">
                    <ChartHashure />
                    <span>Estimativa para conclusão do ciclo em 3 anos</span>
                  </div>
                </div>
              </b-col>
            </div>
            <ui-line-chart id="myLineChart" :data="chartData" :studentYear="year.designation" class="box-chart"></ui-line-chart>
            <span v-b-tooltip.hover title="Progresso esperado" />
          </div>

          <div class="register-container">
            <div class="register-period">
              <div class="title-format">
                <div>
                  <span>REGISTROS DE APRENDIZAGEM <span style="color: #b23066">DO PERÍODO</span></span>
                </div>
              </div>

              <b-row v-if="subjectTopics.length > 0">
                <b-col class="col-status" v-for="topic in subjectTopics" :key="topic.id">
                  <div class="title-status-content">
                    <div class="status-content" :style="topic.status === 'EE'
                      ? 'background-color: #EF6549;'
                      : ''
                      ">
                      <span style="margin: 4px 18.5px; color: #fff">{{
                        topic.status
                        }}</span>
                    </div>
                    <h5>{{ topic.title }}</h5>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="col-status" style="border: none" v-for="topic in subjectTopics" :key="topic.id">
                  <div class="register-item">
                    <ul v-if="topic.topics.length">
                      <span v-for="item in topic.topics" :key="item.id">
                        {{ item.competence_sort_order }}.{{
                        item.topic_sort_order
                        }}
                        {{ item.description }}
                      </span>
                    </ul>

                    <ul v-else>
                      <span>Nenhum conteúdo encontrado</span>
                    </ul>
                  </div>
                </b-col>
              </b-row>
            </div>

            <div class="register-cycle">
              <div class="title-format">
                <div>
                  <span>REGISTROS DE APRENDIZAGEM <span style="color: #b23066">DO CICLO</span></span>
                </div>
              </div>

              <b-row v-if="subjectWithStatus.length > 0">
                <b-col class="col-status" v-for="topic in subjectWithStatus" :key="topic.id">
                  <div class="register-cycle-item">
                    <div class="status-content" :style="topic.status === 'EE'
                      ? 'background-color: #EF6549;'
                      : ''
                      ">
                      <span style="margin: 4px 18.5px; color: #fff">{{
                        topic.status
                        }}</span>
                    </div>
                    <span>
                      {{ topic.totalWithStatus }}/{{ topic.totalValid }}
                    </span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div class="loading-spinner" v-if="loadingChart && !notActivePeriod">
          <span class="spinner-border spinner-border lg"></span>
        </div>
        <div v-if="notActivePeriod">
          <b-alert variant="danger" show>
            <h4 class="alert-heading">Período ativo não encontrado</h4>
            <p>
              Não foi possível encontrar o período ativo para o aluno.
            </p>
          </b-alert>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import httpRda from "../../../http-rda";
import UiLineChart from "@/components/chart/Linechart.vue";
import Flag from "@/components/svg/Flag.vue";
import moment from "moment-timezone";
import ChartHashure from "../../../components/svg/ChartHashure.vue";
export default {
  name: "informe-periodico-detalhes",
  components: {
    UiLineChart,
    Flag,
    ChartHashure,
  },

  data() {
    return {
      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Informe Periódico",
          href: "/comunidade/informe-periodico",
        },
        {
          text: "Aluno",
          href: "/informe-periodico-detalhes",
          active: true,
        },
      ],
      downloadLoading: false,
      loadingChart: true,
      notActivePeriod: false,
      subjectList: [],
      subject_id: null,
      subjectTopics: [],
      subjectWithStatus: [],
      student: null,
      user: null,
      photo: null,
      cycle: null,
      year: null,
      attendances: 0,
      periodReport: null,
      activePeriod: null,
      bimontlyReportData: [],
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "transparent",
            borderColor: "#B23066",
            fill: false,
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },

  computed: {
    ...mapGetters({
      loggedUser: "auth/getAuthData",
    }),

    studentId: {
      get() {
        return this.$route.params.id;
      },
    },

    showButtons() {
      return !this.loggedUser.role.includes("parent");
    },
  },

  methods: {
    async downloadStudentReport() {
      if (!this.periodReport) {
        this.$swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Relatório não encontrado.",
        });
        return;
      }

      try {
        this.downloadLoading = true;
        const response = await httpRda.get(
          `/bimontly-report/${this.studentId}/report`,
          {
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.href = url;

        const fileName = `relatorio-${this.user.name.replace(/\s+/g, '')}-${this.cycle.designation.replace(/\s+/g, '')}-${moment().format("DD-MM-YYYY")}.pdf`

        link.setAttribute(
          "download",
          fileName
        );

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);

        this.downloadLoading = false;
        this.$swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Relatório baixado com sucesso.",
        });
      } catch (error) {
        this.downloadLoading = false;
        this.$swal.fire({
          icon: "error",
          title: "Erro!",
          text: error.response?.data?.error || "Erro ao baixar relatório",
        });
        return error;
      }
    },

    async aprovePeriodReportStatus() {
      if (!this.activePeriod) {
        this.$swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Período não encontrado.",
        });
        return;
      }

      this.$swal
        .fire({
          title: "Notificação",
          text: "Deseja aprovar o relatório?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aprovar",
          cancelButtonText: "Voltar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const hasReport = !!this.periodReport;

              if (!hasReport) {
                await httpRda
                  .post("/period_reports", {
                    period_type: this.activePeriod.periodType,
                    period_id: this.activePeriod.id,
                    student_id: this.studentId,
                    description: "",
                    teacher_id: null,
                    status: "approved",
                  })
                  .then(async () => {
                    await this.getAllData();
                    this.$swal.fire({
                      icon: "success",
                      title: "Sucesso!",
                      text: "",
                    });
                  });
              } else {
                await httpRda
                  .patch(
                    `/bimontly-report/${this.studentId}/report/${this.periodReport.id}/change-period-report-status`,
                    {
                      status: "approved",
                    }
                  )
                  .then(async () => {
                    await this.getAllData();
                    this.$swal.fire({
                      icon: "success",
                      title: "Sucesso!",
                      text: "",
                    });
                  });
              }
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Erro!",
                text: error.response.data.error,
              });

              return error;
            }
          }

          await this.getAllData();
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel concluir a ação!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },

    async pendingPeriodReportStatus() {
      if (!this.periodReport.id) {
        this.$swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Relatório não encontrado.",
        });
        return;
      }

      this.$swal
        .fire({
          title: "Notificação",
          text: "Deseja reverter o status do relatório para pendente?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Reverter",
          cancelButtonText: "Voltar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda
                .patch(
                  `/bimontly-report/${this.studentId}/report/${this.periodReport.id}/change-period-report-status`,
                  {
                    status: "pending",
                  }
                )
                .then(async () => {
                  await this.getAllData();
                  this.$swal.fire({
                    icon: "success",
                    title: "Sucesso!",
                    text: "",
                  });
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Erro!",
                text: error.response.data.error,
              });

              return error;
            }
          }

          await this.getAllData();
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel concluir a ação!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },

    async getStudent() {
      const response = await httpRda.get(`/students/${this.studentId}`);
      this.student = response.data.student || null;
      if (this.student && this.student.User) {
        this.user = this.student.User;
        this.photo = this.student.User.photo || "";
        this.cycle = this.student.Cycle || null;
        this.year = this.student.Year || null;
      }
    },

    async getYearAttendance() {
      const response = await httpRda.get(
        `/students/${this.studentId}/attendances`
      );

      this.attendances = response.data;
    },

    getPeriodReport() {
      httpRda
        .get(`/period_reports/${this.studentId}`)
        .then(response => {
          this.periodReport = response.data.result.periodReport;
          this.activePeriod = response.data.result.activePeriod;
          this.notActivePeriod = false;

          if (this.activePeriod) {
            this.activePeriod.from_date = moment(
              this.activePeriod.from_date
            ).format("DD/MM/YYYY");
            this.activePeriod.to_date = moment(
              this.activePeriod.to_date
            ).format("DD/MM/YYYY");
          }
        })
        .catch(error => {
          this.periodReport = null;
          this.activePeriod = null;
          this.loadingChart = false;
          this.notActivePeriod = true;
          if (error.response.data.error === 'Active period not found') {
            this.$swal.fire({
              icon: "error",
              title: "Erro!",
              text: "Período ativo não encontrado.",
            });
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Erro!",
              text: error.response.data.error,
            });
          }

        });
    },

    async notification(type, payload) {
      const textTutor =
        "Parece que algumas informações estão ausentes. Gostaria de solicitar o tutor a inclusão dos dados necessários?";
      const textTeacher =
        "Parece que algumas informações estão ausentes. Gostaria de solicitar aos professores a inclusão dos dados necessários?";

      this.$swal
        .fire({
          title: "Notificação",
          text: type === "period_tutor" ? textTutor : textTeacher,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Notificar",
          cancelButtonText: "Voltar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda
                .post(
                  `/bimontly-report/${this.studentId}/notifications`,
                  payload
                )
                .then(() => {
                  this.$swal.fire({
                    icon: "success",
                    title: "Notificação enviada!",
                    text: "Notificação enviada com sucesso.",
                  });
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Erro ao notificar!",
                text: error.response.data.error,
              });

              return error;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel notificar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },

    async notifyTutor() {
      await this.notification("period_tutor", {
        type: "period_tutor",
      });
    },

    async notifyTeacher() {
      await this.notification("period_teacher", {
        type: "period_teacher",
        subject_id: this.subject_id,
      });
    },

    async getSubjectList() {
      const response = await httpRda.get(
        `students/${this.studentId}/subjects?specific_order=1`
      );
      this.subjectList = response.data.subjects;

      if (this.subjectList.length > 0) {
        this.selectSubject(this.subjectList[0].id);
      }
    },

    async selectSubject(subject_id) {
      this.loadingChart = true;
      this.subject_id = subject_id;
      const response = await httpRda.get(
        `/bimontly-report/${this.studentId}?subject_id=${subject_id}`
      );

      this.bimontlyReportData = response.data.results || [];

      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];

      this.chartData.labels = this.bimontlyReportData.map(
        (item) => item.to_date
      );
      this.chartData.datasets[0].data = this.bimontlyReportData.map(
        (item) => item.total
      );

      await this.getTopicWithDescription(subject_id);
      await this.getTopicWithStatus(subject_id);

      this.loadingChart = false;
    },

    async getTopicWithDescription(subject_id) {
      const response = await httpRda.get(
        `/students/${this.studentId}/get_topics_with_description`,
        {
          params: {
            subject_id: subject_id,
            status: ["A", "AN", "EE"],
          },
        }
      );

      this.subjectTopics = response.data.topics.reduce((acc, item) => {
        let subjectTopicWithTitle = { ...item, title: "" };

        if (item.status === "A") {
          subjectTopicWithTitle.title = "Conteúdos aprendidos";
          acc.push(subjectTopicWithTitle);
        } else if (item.status === "AN") {
          subjectTopicWithTitle.title = "Conteúdos ainda não aprendidos";
          acc.push(subjectTopicWithTitle);
        } else if (item.status === "EE") {
          subjectTopicWithTitle.title = "Conteúdos em estudo";
          acc.push(subjectTopicWithTitle);
        }

        return acc;
      }, []);
    },

    async getTopicWithStatus(subject_id) {
      const response = await httpRda.get(
        `/students/${this.studentId}/get_topics_with_status`,
        {
          params: {
            subject_id: subject_id,
            status: ["A", "AN", "EE"],
            count: true,
          },
        }
      );

      this.subjectWithStatus = response.data.topics.reduce((acc, item) => {
        if (item.status === "A") {
          acc.push(item);
        } else if (item.status === "AN") {
          acc.push(item);
        } else if (item.status === "EE") {
          acc.push(item);
        }

        return acc;
      }, []);
    },

    async getAllData() {
      await this.getStudent();
      await this.getYearAttendance();
      this.getPeriodReport();
      await this.getSubjectList();
    },
  },

  async created() {
    await this.getAllData();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.scss";

.center-icons {
  display: flex;
  justify-content: space-evenly;
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tags_count {
    background-color: #68c5b7;
    font-size: 18px;
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    padding: 3px 10px;
    height: max-content;
  }
}

.list-top {
  margin-top: 30px;
  margin-bottom: 30px;
}

.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;

  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  p {
    margin: 0;
  }

  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;

      .comma {
        margin-right: 4px;
      }

      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }

  .cycles {
    display: flex;
    flex-wrap: wrap;

    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.outline {
  color: #fff !important;
  background: transparent;
  max-width: 220px !important;
  margin-right: 20px;

  &:hover {
    color: #68c5b7 !important;
  }
}

.col {
  display: flex;
  align-items: center;

  img {
    margin-right: 40px;
  }

  .content-meeting {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;


    h5 {
      font-size: 18px;
      white-space: nowrap;
      margin: 0;
    }

    .observacao-column {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-self: center;
      gap: 8px;

      p {
        margin: 0;
        line-break: anywhere;
        font-size: 18px;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 4px;
      width: 100%;

      p {
        margin: 0;
        font-weight: 400;
        white-space: nowrap;
        font-size: 18px;

        @media (max-width: 1300px) {
          font-size: 14px;
        }
      }

      &.two {
        min-width: 650px;
      }
    }



    .student-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      @media (max-width: 1310px) {
        max-width: 300px;
      }

      p {
        font-size: 18px;
        white-space: nowrap;
        margin: 0;
      }
    }
  }
}

.profile-row {
  background: -webkit-linear-gradient(left, #36b67d, #6883c5);
  border-radius: 10px;
  color: $white;
  margin-top: 30px;
  padding: 28px 10px;
  position: relative;
  width: 100%;
  flex-wrap: nowrap;

  &:after {
    content: none;
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }



  .col {
    display: flex;
    align-items: center;

    img {
      margin-right: 40px;
    }
  }
}

.attendance-advice {
  background-color: #ef6549;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px 10px;

  display: flex;
  align-self: center;
  justify-self: center !important;

  p {
    color: #fff;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
  }
}

.outline {
  color: #68c5b7 !important;
  background: transparent;

  &:hover {
    color: #fff !important;
  }
}

.col-observacoes {
  border-radius: 10px;
  padding: 15px 20px 15px 20px;
  background-color: #fff;
}

.center-itens {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flag-box {
  width: 24px;
  height: 24px;
  background-color: #36b67d;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.subtitle-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  padding: 0;
}

.flag-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  font-size: 16px;

  .subtitle {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}

.status-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #68c5b7;
  widows: 48px;
  height: 28px;
  border-radius: 5px;
  max-width: 48px;
}

.register-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;

  .title-status-content {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: 700;
    gap: 8px;
    border-bottom: 1px solid #b3b3b3;
    width: 100%;

    h5 {
      white-space: nowrap;
    }
  }

  .title-format {
    padding-top: 56px;
    font-size: 20px;
    font-weight: 700;
    color: #666666;
  }

  .register-period {
    display: flex;
    width: 100%;
    gap: 24px;
    flex-direction: column;

    ul {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 340px;
    }

    .register-item {
      align-self: flex-start;
      padding-right: 20px;
    }
  }

  .register-cycle {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .register-cycle-item {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 20px;
      width: 100%;

      & span:first-child {
        font-weight: 700;
      }
    }
  }
}

.chart-container {
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 700;
    padding-bottom: 16px;
    font-size: 20px;
    color: #666666;
  }

  .flag-percentage {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: #666666;

    &:last-child {
      margin-right: 50px;

      .flag-box:before {
        content: "";
        width: 1px;
      }
    }

    .flag-box:before {
      content: "";
      height: 342px;
      width: 5px;
      background-color: #b230665e;
      top: 32px;
      position: absolute;
    }
  }

  .flag-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 24px;
  }
}

.revert-approved-btn {
  margin-left: 25px;
  color: #fff !important;
  background: transparent;
  max-width: 220px !important;
  margin-right: 20px;
  border: solid 1px #ef6549;
  background-color: #ef6549;

  &:hover {
    color: #ef6549 !important;
    border: solid 1px #ef6549 !important;
  }
}

.loading-spinner {
  span {
    color: #b23066;
  }

  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.knowledge-menu {
  padding-left: 10px;

  .menu-sidebar-title {
    padding-bottom: 16px;
    font-weight: 600;
    color: #b3b3b3;
    font-size: 14px !important;
  }

  .side-item,
  .menu-sidebar-title {
    padding: 8px 12px !important;

    span {
      padding: 0px !important;
    }

    white-space: nowrap;
    font-size: 18px;

    &.active {
      &:after {
        content: "";
        background: $gradient1;
        border-radius: 10px;
        display: block;
        height: 24px;
        position: absolute;
        left: 0;
        bottom: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
      }

      &:before {
        all: unset !important;
      }

      background-color: transparent !important;
    }

    &:hover {
      background-color: transparent !important;

      &:after {
        content: "";
        border-radius: 10px;
        display: block;
        position: absolute;
        height: 24px;
        left: 0;
        bottom: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
        background-color: #b3b3b3 !important;
      }

      &:before {
        all: unset !important;
      }
    }
  }
}

.notify-button {
  background-color: transparent !important;
  text-decoration: underline;
  display: inline-block;
  color: #68c5b7;
  font-weight: 700;
  border: 0;
}
</style>
